<template>
    <b-nav
        tabs
        v-cloak
    >
        <b-nav-item
            v-for="app in apps"
            @click="switchApps(app)"
            :active="app === selectedApp"
            :key="app"
        >
            <i
                :class="`${connectionConfig[app].icon} text-${app}`"
                class="fa fa-fw fa-2x"
                aria-hidden="true"
            >
            </i>
        </b-nav-item>
    </b-nav>
</template>

<script>
    import {
        connectionConfigValues,
        profileConnectionConfigValues,
    } from "@/components/apps-connection-config.js";

    export default {
        name: "AppTabs",
        emits: ["update"],
        props: {
            availableApps: {
                type: Array,
                required: false,
            },
        },
        data() {
            return {
                selectedApp: "",
                connectionConfig: connectionConfigValues,
            };
        },
        computed: {
            apps() {
                // Override apps if availableApps is passed
                if (this.$props.availableApps) {
                    return this.sortApps([...this.$props.availableApps]);
                }
                // TODO: use store to get enabled apps
                return Object.keys(profileConnectionConfigValues);
            },
        },
        methods: {
            switchApps(app) {
                this.selectedApp = app;
                this.$emit("update", app);
            },
            sortApps(apps) {
                return apps.sort((a, b) => this.connectionConfig[a].order - this.connectionConfig[b].order);
            },
        },
        mounted() {
            this.switchApps(this.apps[0]);
        },
        watch: {
            availableApps() {
                if (!this.selectedApp || !this.apps.includes(this.selectedApp)) {
                    this.switchApps(this.apps[0]);
                }
            },
        },
    };
</script>
