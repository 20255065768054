<template>
    <div
        v-if="appStatus && appStatus.visible"
        class="col-12 col-md-6 mb-4"
    >
        <div class="card d-flex flex-column h-100 mb-4">
            <div class="card-header flex-grow-0">
                <apps-connection-item
                    v-if="app_slug !== 'emarketing'"
                    :profile="profile"
                    :app="app_slug"
                >
                    <template v-slot:content>
                        <li class="d-flex justify-content-between align-items-center">
                            <!--  left section -->
                            <span class="d-flex align-items-center">
                                <i
                                    :class="`fa ${connectedAppConfig.icon} fa-2x text-${connectedAppConfig.id} align-middle'`"
                                ></i>
                                <span
                                    class="align-middle mx-2 pt-1 text-capitalize"
                                    v-text="appStatus.name"
                                ></span>
                                <plan-badge
                                    v-if="connectedAppConfig.plan === 'pro'"
                                    plan="pro"
                                ></plan-badge>
                            </span>

                            <!-- right section -->
                            <span
                                v-if="appStatus.displayReconnect"
                                class="text-danger"
                            >
                                <small>{{ $t("connection.itemLabelRefreshRequired") }}</small>
                            </span>
                            <span
                                v-else-if="!appStatus.connected"
                                class="text-green"
                            >
                                <small>{{ $t("connection.itemLabelNotConnected") }}</small>
                            </span>
                            <small
                                v-else
                                class="text-green"
                                >{{ $t("connection.itemLabelConnected") }}</small
                            >
                            <span class="d-none">
                                <connect-link :app="connectedAppConfig.id"></connect-link>
                            </span>
                        </li>
                    </template>
                </apps-connection-item>
                <apps-connection-item-em
                    v-if="app_slug === 'emarketing'"
                    :profile="profile"
                    :app="app_slug"
                >
                    <template v-slot:content>
                        <li class="d-flex justify-content-between align-items-center">
                            <span>
                                <i
                                    :class="`fa ${connectedAppConfig.icon} em-icon text-${connectedAppConfig.id} align-middle fa-2x`"
                                ></i>
                                <span class="align-middle mx-2 pt-1 text-capitalize">Client Newsletters</span>
                            </span>
                            <span
                                v-if="!appStatus.is_enabled"
                                class="text-green"
                            >
                                <small>{{ $t("connection.itemLabelNotConnected") }}</small>
                            </span>
                            <small
                                v-else
                                class="text-green"
                                >{{ $t("connection.itemLabelConnected") }}</small
                            >
                        </li>
                    </template>
                </apps-connection-item-em>
            </div>
            <div class="card-body pb-0 flex-grow-1">
                <p
                    v-if="appStatus.marketingText"
                    v-text="appStatus.marketingText"
                ></p>
                <div v-else-if="appStatus.displayReconnect">
                    <p class="text-danger">Your connection needs to be refreshed.</p>
                    <p v-if="toBeExpired">
                        Your {{ appStatus.name }} account is about to be expired in
                        {{ tokenDaysLeftFromNow.toFixed(0) }} days (due to {{ appStatus.name }} security
                        policies). You can prevent disconnection by clicking "Refresh" below.
                    </p>
                    <p v-else>
                        Your {{ appStatus.name }} account is no longer connected. Click on “Refresh” below to
                        reconnect your account.
                    </p>
                </div>
                <div v-else>
                    <p
                        v-if="appData.account_email"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span>{{ appStatus.name }} Account login:</span>
                        <span>{{ appData.account_email }}</span>
                    </p>
                    <p
                        v-else-if="appData.account_name"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span>{{ appStatus.name }} Account login:</span>
                        <span>{{ appData.account_name }} (logged in with phone number)</span>
                    </p>
                    <p
                        v-if="appData.name && app_slug !== 'emarketing'"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span> {{ appStatus.pageTitle }}: </span>
                        <span>
                            <img
                                v-if="appData.image"
                                :src="appData.image"
                                class="align-middle mr-2 rounded-circle border"
                                width="36"
                                height="36"
                                alt="Page logo"
                            />
                            <i
                                :class="`fa ${connectedAppConfig.icon} text-${
                                    connectedAppConfig.id
                                } align-middle ${appData.image ? 'social-media-badge' : 'fa-2x'}`"
                            ></i>
                            <a
                                v-if="appData.name && appData.url"
                                :href="appData.url"
                                target="_blank"
                            >
                                <span
                                    class="align-middle ml-3 pt-1 text-capitalize"
                                    v-text="appData.name"
                                ></span>
                            </a>
                            <span
                                v-else
                                class="align-middle ml-3 pt-1 text-capitalize"
                                v-text="appData.name ? appData.name : appStatus.slug"
                            ></span>
                        </span>
                    </p>
                    <p
                        v-if="tokenDaysLeftFromNow"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span>Connection expires in:</span>
                        <span :class="{ 'text-danger': toBeExpired }">{{ tokenDaysLeftFromNowMessage }}</span>
                    </p>
                    <p
                        v-else-if="app_slug === 'emarketing'"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span>Newsletter Signature:</span>
                        <span>{{ appData.name }}</span>
                    </p>
                </div>
            </div>
            <div class="card-footer flex-grow-0 d-flex justify-content-between">
                <tutorial-video :app_slug="app_slug"></tutorial-video>
                <div class="text-right ml-auto">
                    <template v-if="!profile.completed">
                        <a
                            @click="showSocialMediaProfileModal"
                            href="#"
                            class="btn btn-sm btn-primary ml-2"
                            >{{ $t("connection.itemLabelConnect") }}</a
                        >
                    </template>
                    <template v-else>
                        <a
                            v-if="appStatus.deleteURL"
                            :href="appStatus.deleteURL"
                            class="btn btn-sm btn-outline-danger ml-2"
                            >{{ $t("connection.itemLabelRemove") }}</a
                        >
                        <a
                            v-if="appStatus.displayConnect"
                            :href="appStatus.connectURL"
                            class="btn btn-sm btn-primary ml-2"
                            >{{ $t("connection.itemLabelConnect") }}</a
                        >
                        <a
                            v-if="appStatus.displayUpgradeToPro"
                            @click="showUpgradeModal"
                            href="#"
                            class="btn btn-sm btn-primary ml-2"
                            >{{ $t("connection.itemLabelConnect") }}</a
                        >
                        <a
                            v-if="appStatus.displayReconnect"
                            :href="appStatus.connectURL"
                            class="btn btn-sm btn-primary ml-2"
                            >{{ $t("connection.itemLabelRefresh") }}</a
                        >
                        <a
                            v-if="appStatus.displayChangePage"
                            :href="appStatus.connectURL"
                            class="btn btn-sm btn-outline-primary ml-2"
                            >{{ $t("connection.itemLabelChangePage") }}</a
                        >
                        <a
                            v-if="appStatus.contactListURL"
                            :href="appStatus.contactListURL"
                            class="btn btn-sm btn-outline-primary ml-2"
                            >{{ $t("connection.itemLabelContactList") }}</a
                        >
                        <a
                            v-if="appStatus.signatureURL"
                            :href="appStatus.signatureURL"
                            class="btn btn-sm btn-outline-primary ml-2"
                            >{{ $t("connection.itemLabelSignature") }}</a
                        >
                    </template>
                </div>
            </div>
        </div>
        <reconnect-message-modal
            :id="`${app_slug}-reconnect-message-modal`"
            :app="app_slug"
            :reconnect-url="appStatus.connectURL"
        ></reconnect-message-modal>
        <social-media-profile-modal
            :app="app_slug"
            :redirect-url="redirectUrl"
            :id="`${app_slug}-profile-modal`"
        ></social-media-profile-modal>
    </div>
</template>

<script>
    import AppsConnectionItem from "./AppsConnectionItem.vue";
    import AppsConnectionItemEm from "./AppsConnectionItemEm.vue";
    import ConnectLink from "@/components/connections/ConnectLink.vue";
    import TutorialVideo from "@/components/connections/TutorialVideo.vue";
    import ReconnectMessageModal from "@/components/connections/ReconnectMessageModal.vue";
    import SocialMediaProfileModal from "@/components/connections/SocialMediaProfileModal.vue";
    import { appStringProp } from "@/utils/props.js";
    import { connectionConfigValues } from "./apps-connection-config";
    import { useMainStore } from "@/main-store.js";
    import { views } from "@/components/common/views-config.js";
    import { setDate } from "@/utils/datetime.js";

    export default {
        name: "AppsConnectionCardItem",
        components: {
            AppsConnectionItem,
            AppsConnectionItemEm,
            ConnectLink,
            TutorialVideo,
            ReconnectMessageModal,
            SocialMediaProfileModal,
        },
        props: {
            app_slug: appStringProp,
        },
        data() {
            return {
                connectedAppConfig: connectionConfigValues[this.$props.app_slug],
                mainStore: useMainStore(),
            };
        },
        mounted() {
            this.updateAdditionalLinks();
        },
        watch: {
            appStatus() {
                this.updateAdditionalLinks();
            },
            appData() {
                this.updateAdditionalLinks();
            },
        },
        methods: {
            updateAdditionalLinks() {
                if (this.appStatus) {
                    switch (this.$props.app_slug) {
                        case "facebook":
                            this.appStatus.pageTitle = "Facebook Business Page";
                            if (this.appStatus.installed && this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "facebook");
                                this.appStatus.displayChangePage = true;
                            } else if (this.appStatus.installed && !this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "facebook");
                                this.appStatus.displayReconnect = true;
                            } else {
                                this.appStatus.marketingText = this.$t("connection.marketingFacebook");
                                this.appStatus.tutorialURL = "";
                                this.appStatus.displayConnect = true;
                            }
                            break;
                        case "instagram":
                            this.appStatus.pageTitle = "Instagram App Page";

                            if (this.appStatus.installed && this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "instagram");
                                this.appStatus.displayChangePage = true;
                            } else if (this.appStatus.installed && !this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "instagram");
                                this.appStatus.displayReconnect = true;
                            } else if (window.profileData.applications.instagramfb.active) {
                                // TODO: (insta) - Refactor after deprecation of Instagram FB
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "instagramfb");
                                this.appStatus.displayReconnect = true;
                            } else {
                                this.appStatus.marketingText = this.$t("connection.marketingInstagram");
                                this.appStatus.tutorialURL = "";
                                if (this.profile.plan !== "pro") {
                                    this.appStatus.displayUpgradeToPro = true;
                                } else {
                                    this.appStatus.displayConnect = true;
                                }
                            }
                            break;
                        case "instagramfb":
                            this.appStatus.pageTitle = "Instagram FB Page";
                            if (this.appStatus.installed && this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "instagramfb");
                                this.appStatus.displayChangePage = true;
                            } else if (this.appStatus.installed && !this.appStatus.connected) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "instagramfb");
                                this.appStatus.displayReconnect = true;
                            } else {
                                this.appStatus.marketingText = this.$t("connection.marketingInstagram");
                                this.appStatus.tutorialURL = "";
                                if (this.profile.plan !== "pro") {
                                    this.appStatus.displayUpgradeToPro = true;
                                } else {
                                    this.appStatus.displayConnect = true;
                                }
                            }
                            break;
                        case "linkedin":
                            this.appStatus.pageTitle = "LinkedIn Page";
                            if (this.appStatus.installed && this.appStatus.connected && !this.toBeExpired) {
                                if (
                                    this.appData.organization_urn &&
                                    this.appData.organization_urn.includes("organization")
                                ) {
                                    this.appStatus.pageTitle = "LinkedIn Company Page";
                                }
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "linkedin");
                                this.appStatus.displayChangePage = true;
                            } else if (
                                this.appStatus.installed &&
                                (!this.appStatus.connected || this.toBeExpired)
                            ) {
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "linkedin");
                                this.appStatus.displayReconnect = true;
                            } else {
                                this.appStatus.marketingText = this.$t("connection.marketingLinkedin");
                                this.appStatus.tutorialURL = "";
                                this.appStatus.displayConnect = true;
                            }
                            break;
                        case "emarketing":
                            if (this.appStatus.is_enabled) {
                                this.appStatus.contactListURL = views.emarketing_contact_list(
                                    this.profile.slug
                                );
                                this.appStatus.signatureURL = views.app_settings(
                                    this.profile.slug,
                                    "emarketing"
                                );
                                this.appStatus.deleteURL = views.app_delete(this.profile.slug, "emarketing");
                            } else {
                                this.appStatus.marketingText = this.$t("connection.marketingEmarketing");
                                this.appStatus.tutorialURL = "";
                                this.appStatus.connectURL = views.emarketing_signup(this.profile.slug);
                                this.appStatus.displayConnect = true;
                            }
                            break;
                        default:
                            break;
                    }
                }
            },
            showUpgradeModal() {
                this.$bvModal.show(`upgrade-${this.$props.app_slug}-modal`);
            },
            showPageSelectorModal() {
                this.$bvModal.show(`${this.$props.app_slug}-page-selector`);
            },
            showSocialMediaProfileModal() {
                this.$bvModal.show(`${this.$props.app_slug}-profile-modal`);
            },
        },
        computed: {
            appStatus() {
                return this.mainStore[`${this.$props.app_slug}`];
            },
            appData() {
                return this.mainStore[`${this.$props.app_slug}_data`];
            },
            profile() {
                return this.mainStore["profile"];
            },
            tokenDaysLeftFromNow() {
                if (this.appData.expires_at) {
                    return setDate.daysLeftFromNow(this.appData.expires_at);
                }
                return null;
            },
            tokenDaysLeftFromNowMessage() {
                if (this.tokenDaysLeftFromNow > 0) {
                    return `${this.tokenDaysLeftFromNow.toFixed(0)} days from now`;
                }
                if (this.tokenDaysLeftFromNow <= 0) {
                    return "Expired";
                }
                return "";
            },
            toBeExpired() {
                return (
                    this.tokenDaysLeftFromNow &&
                    this.connectedAppConfig.daysToMarkAsToBeExpired &&
                    this.tokenDaysLeftFromNow <= this.connectedAppConfig.daysToMarkAsToBeExpired
                );
            },
            redirectUrl() {
                // If user is not pro and is trying to connect to Instagram, redirect to the plan page
                if (
                    this.profile.plan !== "pro" &&
                    ["instagram", "instagramfb"].includes(this.$props.app_slug)
                ) {
                    return views.profile_plan(this.profile.slug);
                }
                return this.appStatus.connectURL;
            },
        },
    };
</script>

<style lang="css" scoped>
    .social-media-badge {
        margin-left: -1.3em;
        margin-top: 1.3em;
        font-size: 1em;
        background-color: #fff;
    }

    .card-header {
        min-height: 61.8px;
        background-color: #fff;
    }
</style>
