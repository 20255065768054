<template>
    <p class="small mb-0">
        <span
            v-if="deleted"
            class="text-danger mr-1"
        >
            <i
                class="fa fa-exclamation"
                aria-hidden="true"
            ></i>
            <span v-if="post.status == 0">{{ $t("common.deleted") }}</span>
            <span v-else>{{ $t("common.deletedByFrequency") }}</span>
        </span>
        <span
            v-if="posted"
            class="text-success mr-1"
        >
            <i
                class="fa fa-check"
                aria-hidden="true"
            ></i>
            {{ $t("common.posted") }}
        </span>
        <span v-if="publishing">
            <i
                class="fa fa-spinner fa-spin"
                aria-hidden="true"
            ></i>
            {{ $t("common.publishing") }}
        </span>
        <span
            v-if="scheduled"
            class="mr-1"
        >
            <i
                class="fa fa-clock-o"
                aria-hidden="true"
            ></i>
            {{ $t("common.scheduled") }}
        </span>

        <template v-if="!publishing">
            {{ post.fire_at_formatted }}
        </template>
    </p>
</template>
<script>
    import { setDate } from "@/utils/datetime";

    export default {
        name: "PostStatusLabel",
        props: {
            post: {
                type: Object,
                required: true,
            },
        },
        computed: {
            now() {
                return setDate.now();
            },
            deleted() {
                return this.post.deleted;
            },
            posted() {
                return this.post.status == 10;
            },
            publishing() {
                return this.post.status == 0 && this.post.fire_at < this.now;
            },
            scheduled() {
                return this.post.status == 0 && this.post.fire_at >= this.now;
            },
        },
    };
</script>
