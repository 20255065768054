export const connectionConfigValues = {
    facebook: {
        id: "facebook",
        provider: "fbpage",
        page_id: "page_id",
        name: "Facebook",
        icon: "fa-facebook-square",
        order: 1,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/facebook.jpg",
        stats: [
            {
                label: "common.reach",
                field: "post_impressions_unique",
            },
            {
                label: "common.engagement",
                field: "post_engaged_users",
            },
            {
                label: "common.clicks",
                field: "post_clicks",
            },
        ],
    },
    instagram: {
        id: "instagram",
        provider: "instagram",
        page_id: "page_id",
        name: "Instagram",
        icon: "fa-instagram",
        plan: "pro",
        order: 2,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/instagram.jpg",
        needsPro: true,
        stats: [
            {
                label: "common.reach",
                field: "post_impressions_unique",
            },
            {
                label: "common.engagement",
                field: "post_engaged_users",
            },
            {
                label: "common.clicks",
                field: "post_clicks",
            },
        ],
    },
    instagramfb: {
        id: "instagramfb",
        provider: "instagramfb",
        page_id: "page_id",
        name: "Instagram",
        icon: "fa-instagram",
        plan: "pro",
        order: 2,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/instagram.jpg",
        needsPro: true,
        stats: [
            {
                label: "common.reach",
                field: "post_impressions_unique",
            },
            {
                label: "common.engagement",
                field: "post_engaged_users",
            },
            {
                label: "common.clicks",
                field: "post_clicks",
            },
        ],
    },
    linkedin: {
        id: "linkedin",
        provider: "linkedin",
        page_id: "organization_urn",
        name: "LinkedIn",
        icon: "fa-linkedin-square",
        order: 3,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/linkedin.jpg",
        stats: [
            {
                label: "common.likes",
                field: "post_likes_summary",
            },
            {
                label: "common.comments",
                field: "post_comments_summary",
            },
            {
                label: "common.clicks",
                field: "post_clicks_summary",
            },
        ],
        daysToMarkAsToBeExpired: 21,
    },
    emarketing: {
        id: "emarketing",
        name: "Client Newsletters",
        icon: "fa-envelope-o",
        order: 4,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/emarketing.jpg",
    },
};

let _profileConnectionConfigValues = {};
let _profileSocialConnectionConfigValues = {};

// Check if there's applications and profile
if (window.profileData?.pk !== undefined && window.profileData.pk !== "") {
    const igEnabled = window.profileData.applications["instagram"].is_enabled;
    const igFBEnabled = window.profileData.applications["instagramfb"].is_enabled;
    let igKeyToShow = "instagram";
    if (igFBEnabled && !igEnabled) {
        igKeyToShow = "instagramfb";
    }

    _profileConnectionConfigValues = Object.fromEntries(
        Object.entries(connectionConfigValues)
            // Show the corresponding Instagram key
            .filter(
                (entry) =>
                    window.profileData.applications[entry[0]].is_enabled &&
                    ((entry[0].startsWith("instagram") && entry[0] === igKeyToShow) ||
                        !entry[0].startsWith("instagram"))
            ) // Sort apps by order
            .sort((a, b) => a[1].order - b[1].order)
    );

    _profileSocialConnectionConfigValues = Object.fromEntries(
        Object.entries(_profileConnectionConfigValues)
            // Remove emarketing from profileConnectionConfigValues
            .filter((entry) => entry[0] !== "emarketing")
            // Sort apps by order
            .sort((a, b) => a[1].order - b[1].order)
    );
}

export const profileConnectionConfigValues = _profileConnectionConfigValues;
export const profileSocialConnectionConfigValues = _profileSocialConnectionConfigValues;
