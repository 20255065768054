<template>
    <div class="position-relative">
        <img
            v-lazy="{ src: post.image }"
            @load="handleResize"
            alt=""
            class="img-cover-container"
            :class="customClass"
            ref="postImage"
        />
        <img
            v-if="post.has_custom_image && profileLogo"
            :src="profileLogo"
            alt=""
            class="profile-logo"
            ref="profileLogo"
        />
    </div>
</template>
<script>
    import { useMainStore } from "@/main-store";

    export default {
        name: "PostImage",
        props: {
            post: {
                // ScheduledPosting object is required
                type: Object,
                required: true,
            },
            customClass: {
                type: String,
                default: "img-fluid",
            },
        },
        data: function () {
            return {
                mainStore: useMainStore(),
            };
        },
        computed: {
            profileLogo() {
                return this.mainStore.profile.logo_image;
            },
        },
        mounted: function () {
            window.addEventListener("resize", this.handleResize);
            this.$nextTick(() => {
                this.handleResize();
            });
        },
        beforeUnmount: function () {
            window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            handleResize() {
                // Resize profile logo
                const postImage = this.$refs.postImage;
                const profileLogo = this.$refs.profileLogo;
                if (postImage && profileLogo) {
                    const height = postImage.clientHeight;
                    profileLogo.style.height = `${height / 4}px`;
                }
            },
        },
    };
</script>
<style scoped>
    /* Set initial size for logo image */
    .profile-logo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 100px;
    }
    /* Set image size to cover container */
    .img-cover-container {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>
